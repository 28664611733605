import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { sendCalendlyAccessTokenToBackend } from '../../service/service'; 
import { useUser } from '@clerk/clerk-react';

const CALENDLY_CLIENT_ID = process.env.REACT_APP_CALENDLY_CLIENT_ID;
const CALENDLY_CLIENT_SECRET = process.env.REACT_APP_CALENDLY_CLIENT_SECRET;

const CalendlyLoginHandler = () => {
  const history = useHistory();
  const { isLoaded, user } = useUser();
  const [userEmail, setUserEmail] = useState('');
  const [authCode, setAuthCode] = useState('');

  useEffect(() => {
    if (isLoaded && user) {
      setUserEmail(user.primaryEmailAddress.emailAddress);

      // Retrieve the authorization code from URL
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get('code');
      if (code) {
        setAuthCode(code);
      } else {
        console.error('Calendly Login was cancelled or failed');
        history.push('/dashboard');
      }
    }
  }, [user, isLoaded, history]);

  useEffect(() => {
    // Only proceed if both userEmail and authCode are available
    if (userEmail && authCode) {
      exchangeCodeForAccessToken(authCode);
    }
  }, [userEmail, authCode, history]);

  const exchangeCodeForAccessToken = async (code) => {
    try {
      const response = await fetch('https://auth.calendly.com/oauth/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
          grant_type: 'authorization_code',
          code: code,
          redirect_uri: 'https://platform.funnelsystems.ai/calendly-login-handler',
          client_id: CALENDLY_CLIENT_ID,
          client_secret: CALENDLY_CLIENT_SECRET
        })
      });
      const data = await response.json();
      if (data.access_token) {
        sendToBackend(data.access_token); // Function to send token to your backend
        history.push('/dashboard');
      } else {
        throw new Error('Failed to receive access token.');
      }
    } catch (error) {
      console.error('Failed to exchange code for access token:', error);
      history.push('/dashboard');
    }
  };

  const sendToBackend = (token) => {
    sendCalendlyAccessTokenToBackend(userEmail, token);
  };

  return <div>Handling Calendly login response...</div>;
};

export default CalendlyLoginHandler;
