const SAVE_PERSONALITY_TO_DATABASE_ENDPOINT = process.env.REACT_APP_SAVE_PERSONALITY_TO_DATABASE_ENDPOINT;
const FETCH_PERSONALITY_FROM_DATABASE_ENDPOINT = process.env.REACT_APP_FETCH_PERSONALITY_FROM_DATABASE_ENDPOINT;
const TEST_CHATBOT_SERVICE_ENDPOINT = process.env.REACT_APP_TEST_CHATBOT_SERVICE_ENDPOINT;
const MAKE_TEST_THREAD_SERVICE_ENDPOINT = process.env.REACT_APP_MAKE_TEST_THREAD_SERVICE_ENDPOINT;
const EXCHANGE_FB_LOGIN_TOKEN_ENDPOINT = process.env.REACT_APP_EXCHANGE_FB_LOGIN_TOKEN_ENDPOINT;
const INSTAGRAM_GRAPH_API_ENDPOINT = process.env.REACT_APP_INSTAGRAM_GRAPH_API_ENDPOINT;
const GENERATE_QUALIFICATION_RESULT_ENDPOINT = process.env.REACT_APP_GENERATE_QUALIFICATION_RESULT_ENDPOINT;
const LOGIN_WITH_INSTAGRAM_ENDPOINT = process.env.REACT_APP_LOGIN_WITH_INSTAGRAM_ENDPOINT;
const SOLVE_2FA_AUTHENTICATION_ENDPOINT = process.env.REACT_APP_SOLVE_2FA_AUTHENTICATION_ENDPOINT;
const SEND_CALENDLY_ACCESS_TOKEN_ENDPOINT = process.env.REACT_APP_SAVE_CALENDLY_ACCESS_TOKEN_ENDPOINT;

const sendFormData = async (fullFormData) => {
    console.log(fullFormData)
  try {
    const response = await fetch(SAVE_PERSONALITY_TO_DATABASE_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(fullFormData),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log('Success:', data);
    return data;
  } catch (error) {
    console.error('Error sending form data:', error);
  }
};

const getPersonalityData = async (userEmail, sessionToken) => {
  try {
    const response = await fetch(FETCH_PERSONALITY_FROM_DATABASE_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userEmail, sessionToken }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log('Retrieved personality data:', data);
    return data;
  } catch (error) {
    console.error('Error fetching personality data:', error);
    throw error;
  }
};

const sendMessageToTestChatbotService = async (fullFormData, message, testconversation_openai_thread_id, clerk_user_id, isDivalogue) => {
  console.log("Sending now to backend:", message)
  try {
    const response = await fetch(TEST_CHATBOT_SERVICE_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...fullFormData, message, testconversation_openai_thread_id, clerk_user_id, isDivalogue }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log('Success with message:', data);
    return data;
  } catch (error) {
    console.error('Error sending form data with message:', error);
  }
};

const requestThreadId = async () => {
  try {
    const response = await fetch(MAKE_TEST_THREAD_SERVICE_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: "",
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log('Success:', data);
    return data;
  } catch (error) {
    console.error('Error sending form data:', error);
  }
};

const exchangeTokenForLongLivedToken = async (shortLivedToken, clerkUserId) => {
  console.log(clerkUserId)
  try {
    const response = await fetch(EXCHANGE_FB_LOGIN_TOKEN_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // Include clerk_user_id in the body of your request
      body: JSON.stringify({ shortLivedToken, clerkUserId }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log('Success exchanging token:', data);
    return data; // Ensure your server response contains this key
  } catch (error) {
    console.error('Error exchanging token:', error);
    throw error; // Rethrow the error to the caller for further handling
  }
};

const fetchInstagramData = async (longLivedToken) => {
  try {
    const fields = 'id,name,access_token,instagram_business_account';
    const url = `${INSTAGRAM_GRAPH_API_ENDPOINT}/me/accounts?fields=${encodeURIComponent(fields)}&access_token=${longLivedToken}`;
    const response = await fetch(url);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    // Error handling
    throw error;
  }
};

const fetchQualificationResult = async (idealClientProfile, testingICP, additional_instructions_for_qualification) => {
  try {
    const response = await fetch(GENERATE_QUALIFICATION_RESULT_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ideal_client_profile: idealClientProfile, testing_ICP: testingICP, additional_instructions_for_qualification: additional_instructions_for_qualification }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log('Qualification result:', data);
    return data;
  } catch (error) {
    console.error('Error generating qualification result:', error);
    throw error;
  }
};

const loginWithInstagram = async (username, password, userEmail, deviceProfile) => {
  console.log("deviceProfile:", deviceProfile)
  try {
    const response = await fetch(LOGIN_WITH_INSTAGRAM_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password, userEmail, deviceProfile }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log('Success:', data);
    return data;
  } catch (error) {
    console.error('Error during Instagram login:', error);
    throw error; 
  }
};

const solveInstagramTwoFactorAuthentication = async (username, verificationCode, userEmail, deviceProfile) => {
  try {
    const response = await fetch(SOLVE_2FA_AUTHENTICATION_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, verification_code: verificationCode, userEmail, deviceProfile }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log('Success:', data);
    return data;
  } catch (error) {
    console.error('Error during 2FA resolution:', error);
    throw error; 
  }
};

const sendCalendlyAccessTokenToBackend = async (email, accessToken) => {
  console.log("Email:", email)
  console.log("Access token:", accessToken)
  try {
    const response = await fetch(SEND_CALENDLY_ACCESS_TOKEN_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, accessToken }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status}`);
    }

    const data = await response.json();
    console.log('Success sending Calendly access token:', data);
    return data; 
  } catch (error) {
    console.error('Error sending Calendly access token:', error);
    throw error; 
  }
};

export { solveInstagramTwoFactorAuthentication, sendCalendlyAccessTokenToBackend, loginWithInstagram, fetchInstagramData, fetchQualificationResult, requestThreadId, sendFormData, getPersonalityData, sendMessageToTestChatbotService, exchangeTokenForLongLivedToken };

