import { useReactFlow } from 'reactflow';
import { useFormContext } from '../../../contexts/FormContext'; // Import context

function useEdgeClick(id) {
  const { setEdges, setNodes, getNode, getEdge } = useReactFlow();
  const { updateWorkflowStep, workflowSteps, setWorkflowSteps } = useFormContext();  // Pull context here

  const handleEdgeClick = () => {
    const edge = getEdge(id);

    if (!edge) {
      return;
    }

    // Retrieve the edge's source and target nodes using their IDs
    const sourceNode = getNode(edge.source);
    const targetNode = getNode(edge.target);

    if (!targetNode) {
      return;
    }

    const insertNodeId = String(workflowSteps.length + 1);  // Adjusted to prevent UUID use for consistency with existing code

    const insertNode = {
      id: insertNodeId,
      position: { x: targetNode.position.x, y: targetNode.position.y },
      data: { label: '<new node>' },  // Replace with actual initial data
      type: 'workflow',
      stepOrder: targetNode.data.stepOrder  // Take the step order from the target node
    };

    const sourceEdge = {
      id: `${edge.source}-${insertNodeId}`,
      source: edge.source,
      target: insertNodeId,
      type: 'workflow',
    };

    const targetEdge = {
      id: `${insertNodeId}-${edge.target}`,
      source: insertNodeId,
      target: edge.target,
      type: 'workflow',
    };

    // Remove the clicked edge and add new edges
    setEdges((edges) => edges.filter((e) => e.id !== id).concat([sourceEdge, targetEdge]));

    // Insert the node and adjust step orders -- insert before the target node
    setNodes((nodes) => {
      const targetIndex = nodes.findIndex((n) => n.id === edge.target);
      const newNodes = [...nodes];
      newNodes.splice(targetIndex, 0, insertNode);
      return newNodes;
    });

    // Update the step orders to reflect the new order
    setWorkflowSteps((steps) => {
      const targetIndex = steps.findIndex((step) => step.nodeId === targetNode.id);
      const newStepsOrder = steps.map((step, index) => ({
        ...step,
        order: index < targetIndex ? index + 1 : index + 2, // increment order for steps after targetIndex
        stepOrder: index < targetIndex ? index + 1 : index + 2,
      }));
    
      const newStep = {
        ...insertNode.data,
        nodeId: insertNodeId,
        order: targetIndex + 1,
        stepOrder: targetIndex + 1,
      };
      newStepsOrder.splice(targetIndex, 0, newStep); // insert new step at the target index with proper order
    
      return newStepsOrder;
    });
  };

  return handleEdgeClick;
}

export default useEdgeClick;