import React, { useState, useContext } from 'react';
import { useFormContext } from '../../contexts/FormContext'

function convertUnicodeToEmoji(text) {
  return text.replace(/\\u[\dA-F]{4}/gi, 
      match => String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16))
  );
}

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const { sendMessageToTestChatbot, setTestConversationStep, setQualificationResult, setInChatQualificationResult, setIsQualified, setInChatQualificationScore } = useFormContext()
  const [loading, setLoading] = useState(false);

  const clearChat = () => {
    console.log("clearing")
    setTestConversationStep("");
    setInChatQualificationResult("");
    setInChatQualificationScore(0);
    setState(prev => ({
      ...prev,
      messages: []
    }));
  };

  const sendMessageToBackend = async (message) => {
    setLoading(true);
    try {
      const testconversation_openai_thread_id = localStorage.getItem('test_thread_openai_id');
      const chatbotData = await sendMessageToTestChatbot(message, testconversation_openai_thread_id);
      console.log(chatbotData)

      const qualificationResult = chatbotData.qualification_result.combined_result?.reasoning;
      setInChatQualificationResult(qualificationResult);

      const isQualified = chatbotData.is_qualified;
        
      console.log(qualificationResult)
      setIsQualified(isQualified);

      const qualifiedScore = chatbotData.qualification_result.combined_result?.ideal_client_score;
      setInChatQualificationScore(qualifiedScore);
      console.log(qualifiedScore)

      const replyMessage = chatbotData.main_response;

      if (replyMessage != "cancelled") {
        const stepSelectionResultObj = JSON.parse(chatbotData.step_selection_result);
        const instructionName = stepSelectionResultObj.instruction_name;
        
        setTestConversationStep(instructionName)

        const replyMessageWithEmojis = convertUnicodeToEmoji(replyMessage)

        const botMessage = createChatBotMessage(replyMessageWithEmojis)
        console.log(botMessage)

        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, botMessage],
      }));
      }
    } catch (error) {
      console.error("Failed to send message to backend:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: { sendMessageToBackend, clearChat },
          loading,
        });
      })}
    </div>
  );
};

export default ActionProvider;
