import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import RtlLayout from 'layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';
import { FormProvider } from 'contexts/FormContext';
import { ClerkProvider, SignedIn, SignedOut, RedirectToSignIn } from "@clerk/clerk-react";
import { ColorModeScript } from '@chakra-ui/react'
import InstagramLoginHandler from 'components/facebook/InstagramLoginHandler';
import CalendlyLoginHandler from 'components/calendly/CalendlyLoginHandler';


if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
	throw new Error("Missing Publishable Key")
}

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

// Component to enforce authentication
const ProtectedRoute = ({ component: Component, ...rest }) => (
  <SignedIn>
    <Component {...rest} />
  </SignedIn>
);

// Component to handle redirection if not signed in
const UnprotectedRoute = () => (
  <SignedOut>
    <RedirectToSignIn />
  </SignedOut>
);

if (process.env.REACT_APP_PLATFORM === "DIVALOGUE") {
  localStorage.setItem('chakra-ui-color-mode', "light")
} else {
  localStorage.setItem('chakra-ui-color-mode', "dark")
}


ReactDOM.render(
  <ClerkProvider publishableKey={clerkPubKey}>
    <FormProvider>
      <ChakraProvider theme={theme}>
        <React.StrictMode>
          <ThemeEditorProvider>
            <BrowserRouter> {/* Use BrowserRouter here */}
              <Switch>
                <Route path="/instagram-login-handler" component={InstagramLoginHandler} />
                <Route path="/calendly-login-handler" component={CalendlyLoginHandler} />
                <Route path={`/auth`} component={AuthLayout} />
                <Route
                  path={`/`}
                  render={(props) => (
                    <>
                      <ProtectedRoute component={AdminLayout} {...props} />
                      <UnprotectedRoute />
                    </>
                  )}
                />
                <Route
                  path={`/rtl`}
                  render={(props) => (
                    <>
                      <ProtectedRoute component={RtlLayout} {...props} />
                      <UnprotectedRoute />
                    </>
                  )}
                />
                <Redirect from='/' to='/' />
              </Switch>
            </BrowserRouter>
          </ThemeEditorProvider>
        </React.StrictMode>
      </ChakraProvider>
    </FormProvider>
  </ClerkProvider>,
  document.getElementById('root')
);