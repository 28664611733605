import { createChatBotMessage } from 'react-chatbot-kit';

// Import your custom components
import BotAvatar from './BotAvatar';
import UserAvatar from './UserAvatar';
import BotChatMessage from './BotChatMessage';
import UserChatMessage from './UserChatMessage';
import BotHeader from './BotHeader';

const config = {
    initialMessages: [createChatBotMessage("")],
    botName: "Wayy-I",
    customComponents: {
        // Replaces the default header
        header: BotHeader,
        // Replaces the default bot avatar
        botAvatar: BotAvatar,  // Using your custom bot avatar component
        // Replaces the default user avatar
        userAvatar: UserAvatar,  // Using your custom user avatar component
        // Replaces the default bot chat message container
        botChatMessage: BotChatMessage,  // Using your custom bot chat message component
        // Replaces the default user chat message container
        userChatMessage: UserChatMessage,  // Using your custom user chat message component
    },
};

export default config;
