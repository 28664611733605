import React from 'react';
import {
  Button,
  useColorModeValue,
} from '@chakra-ui/react';

const CalendlyLoginButton = ({ clientId, redirectUri }) => {
  const handleLoginClick = () => {
    const loginUrl = `https://auth.calendly.com/oauth/authorize?client_id=${clientId}&response_type=code&redirect_uri=${encodeURIComponent(redirectUri)}`;
    window.location.href = loginUrl;
  };

  const brandColor = useColorModeValue('#3d0048', '#7bd1ff');

  const brandShadeColor = useColorModeValue('#3d0048', '#00324d');
  // Get text color for the button based on the light or dark mode
  const buttonTextColor = useColorModeValue('white', 'white');

  const deleteButtonStyles = {
    _hover: {
      transform: 'scale(1.1)',
    },
    transition: 'transform 0.2s ease-in-out', // Smooth transition for transform
  };


  return (
    <Button 
      fontSize="16px"
      mt="48px"
      w="30%" 
      h="40px"
      borderColor={brandShadeColor}
      borderRadius="8px"
      borderWidth={"1px"}
      color={buttonTextColor} 
      mb="16px" 
      onClick={handleLoginClick}
      position="relative"
      overflow="hidden"
      transition="transform 0.2s ease-in-out"
      // Adjust the gradient to be lighter and more subtle
      background={brandShadeColor}
      boxShadow={`0 0 0 1px ${brandShadeColor}`}
      _hover={{ 
          boxShadow: `0 0 0 2px ${brandShadeColor}`,
          _hover: {
            bg: brandColor,
            borderColor: brandColor,
                },
            }}
        >
      Connect Calendly
    </Button>
  );
};

export default CalendlyLoginButton;
