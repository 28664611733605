import React, { createContext, useState, useContext, useCallback, useEffect } from 'react';
import { sendFormData, sendMessageToTestChatbotService } from '../service/service'; 
import { useUser, useAuth } from '@clerk/clerk-react';
import { useReactFlow, getConnectedEdges, isNode } from 'reactflow';
import {
  useToast,
  Box,
  Text
} from '@chakra-ui/react';

const FormContext = createContext();

export const useFormContext = () => useContext(FormContext);

export const FormProvider = ({ children }) => {
    const { isLoaded, user } = useUser();
    const { getToken } = useAuth();
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [testConversationStep, setTestConversationStep ] = useState("");
    const [qualificationResult, setQualificationResult] = useState(null);
    const [inChatQualificationResult, setInChatQualificationResult] = useState(null);
    const [currentThreadId, setCurrentThreadId ] = useState("")
    const [isReplying, setIsReplying] = useState(false); 
    const [isQualified, setIsQualified] = useState(false);
    const [inChatQualificationScore, setInChatQualificationScore] = useState(0);

    const toast = useToast();

    const SuccessToast = () => (
      <Box color="white" p={3} bg="#28a745" borderRadius="md" minW="250px">
        <Text>Data saved successfully.</Text>
      </Box>
    );
    
    const ErrorToast = ({ message }) => (
      <Box color="white" p={3} bg="#dc3545" borderRadius="md" minW="250px">
        <Text>Error: {message}</Text>
      </Box>
    );

    const [formData, setFormData] = useState({
        basePersonality: {
          product_knowledge_text: '',
          general_knowledge_text: '',
          goals: '',
          link: '',
        },
        communicationStyle: {
          general_style_description: '',
          dont_respond_to_description: '',
        },
        conversationStrategy: {
          instructions_for_step_selection_assistant: '',
        },
        personalIdentity: {
          name: '',
          age: '',
          gender: '',
          pronouns: '',
          origin: '',
          race: '',
          profession: '',
          hobbies: '',
          skills: '',
          values_motivations: '',
          general_facts: '',
        },
        behaviourExample: {
          behaviour_example: '',
        },
        messagebirdData: {
          messagebird_channel_id: '',
          messagebird_workspace_id: '',
        },
        postProcessing: {
          prost_processing_instructions: '',
        },
        humanEscalation: {
          keywords: [],
        },
        qualification: {
          additional_instructions_for_qualification: '',
          min_qualification_score: '',
          qualify_at_message_nr: '',
          idealClientProfile: [],
          testingICP: [],
        },
        deviceProfile: {
          phone_id_and_uuid: '',
          advertising_id: '',
          android_device_id: '',
        },
        conversationStages: {
          stages: [],
          rules: [],
      },
        usernames: [],
      });
  const [workflowSteps, setWorkflowSteps] = useState([]);

  const setInitialWorkflowSteps = useCallback((steps) => {
    setWorkflowSteps(steps.map((step, index) => ({
      ...step,
      nodeId: String(index + 1),
      order: index + 1, // Set order based on array index if needed
    })));
  }, []);

  const updateFormData = useCallback((section, value) => {
    setFormData((prevData) => {
      // Check if the value is an array
      if (Array.isArray(value)) {
        return {
          ...prevData,
          [section]: value, // Directly set the array
        };
      } else if (typeof value === 'object' && value !== null) {
        // For object values, merge with existing data
        return {
          ...prevData,
          [section]: {
            ...prevData[section],
            ...value,
          },
        };
      } else {
        // For non-object and non-array values, set directly
        return {
          ...prevData,
          [section]: value,
        };
      }
    });
    console.log(formData)
  }, []);
  

  const generateCombinedFormData = async () => {
    const token = await getToken();
    const email = user?.primaryEmailAddress.emailAddress;
  
    const sortedSteps = workflowSteps
      .slice()
      .sort((a, b) => a.stepOrder - b.stepOrder)
      .map((step, index) => ({ ...step, order: index + 1 }));
  
    return {
      ...formData,
      conversationStrategy: {
        ...formData.conversationStrategy,
        steps: sortedSteps,
      },
      userEmail: email,
      sessionToken: token,
    };
};

useEffect(() => {
  console.log(formData)
}, [formData])

// Modified saveFormData function
const saveFormData = useCallback(async () => {
  if (!isLoaded) {
    console.error('User data is not loaded yet');
    return;
  }

  const combinedFormData = await generateCombinedFormData();

  if(!combinedFormData.qualification.idealClientProfile) {
    combinedFormData.qualification.idealClientProfile = []
  }

  if(!combinedFormData.qualification.testingICP) { 
    combinedFormData.qualification.testingICP = []
  }

  if(!combinedFormData.humanEscalation.keywords) {
    combinedFormData.humanEscalation.keywords = []
  }
  const filteredIdealClientProfile = combinedFormData.qualification.idealClientProfile.filter(pair => pair.key.trim() || pair.value.trim());
  const filteredKeywords = formData.humanEscalation.keywords.filter(keyword => keyword.trim());

  const filteredFormData = {
    ...combinedFormData,
    qualification: {
      ...combinedFormData.qualification,
      idealClientProfile: filteredIdealClientProfile,
    },
    humanEscalation: {
      ...formData.humanEscalation,
      keywords: filteredKeywords
    }
  };

  console.log('Saving:', filteredFormData);
  try {
    console.log('Saving:', filteredFormData);
    const result = await sendFormData(filteredFormData);
    if (result) {
      toast({
        position: 'bottom-left',
        render: () => <SuccessToast />,
      });
    }
  } catch (error) {
    console.error('Error saving form data:', error);
    toast({
      position: 'bottom-left',
      render: () => <ErrorToast message={error.message} />,
    });
  }
}, [isLoaded, generateCombinedFormData]);

  const updateWorkflowStep = useCallback((nodeId, stepData) => {
    setWorkflowSteps((prevSteps) => {
      const index = prevSteps.findIndex(step => step.nodeId === nodeId);
      if (index > -1) {
        // Update step in-place
        const newSteps = [...prevSteps];
        newSteps[index] = { ...newSteps[index], ...stepData };
        return newSteps;
      } else {
        // Add new step
        return [...prevSteps, { nodeId, ...stepData }];
      }
    });
  }, []);

  const handleSave = useCallback(async () => {
    const payload = {
      ...formData,
      conversationStrategy: {
        ...formData.conversationStrategy,
        steps: workflowSteps.map((step, index) => ({
          ...step,
          order: index + 1,
        })),
      },
    };
    await sendFormData(payload);
  }, [formData, workflowSteps]);  // Ensure this useCallback dependency array is accurate

  const collectFormData = useCallback(() => ({
    ...formData,
    conversationStrategy: {
      ...formData.conversationStrategy,
      steps: workflowSteps.map((step, index) => ({
        ...step,
        order: index + 1, // Set order based on array index
      })),
    },
  }), [formData, workflowSteps]);

  const deleteWorkflowStep = useCallback(nodeId => {
    setWorkflowSteps((prevSteps) => {
      const nodeToDelete = prevSteps.find(step => step.nodeId.toString() === nodeId.toString());
      if (!nodeToDelete) return prevSteps; // Node not found in the context state

      const remainingSteps = prevSteps.filter(step => step.nodeId !== nodeId);
      // Remove the corresponding node from the React Flow UI
      setNodes(prevNodes => prevNodes.filter(node => node.id !== nodeId));

      const connectedEdges = getConnectedEdges([nodeToDelete], edges);
      setEdges(prevEdges => prevEdges.filter(edge => !connectedEdges.includes(edge)));

      // Automatically reconnect nodes if they were connected in a series
      const incomersEdges = edges.filter(edge => edge.target === nodeId);
      const outgoersEdges = edges.filter(edge => edge.source === nodeId);

      const sourceFromIncomers = incomersEdges.map(edge => edge.source);
      const targetsOfOutgoers = outgoersEdges.map(edge => edge.target);

      const edgesToReconnect = sourceFromIncomers.flatMap(source =>
        targetsOfOutgoers.map(target => ({
          id: `reconnected-${source}-${target}`,
          source,
          target,
          animated: true, // Assuming we want to highlight the reconnected edges
        })),
      );

      setEdges(prevEdges => [
        ...prevEdges.filter(edge => !connectedEdges.includes(edge)),
        ...edgesToReconnect,
      ]);

      // Return the new state of steps without the deleted one
      console.log(remainingSteps)
      return remainingSteps;
    });
  }, [nodes, edges, setNodes, setEdges]);

  const sendMessageToTestChatbot = useCallback(async (message, testconversation_openai_thread_id) => {
    console.log("Sending to test chatbot service:", message, testconversation_openai_thread_id)
    if (!isLoaded) {
      console.error('User data is not loaded yet');
      return;
    }
    
    const combinedFormData = await generateCombinedFormData();
    const clerk_user_id = user.id;

    const isDivalogue = process.env.REACT_APP_PLATFORM === "DIVALOGUE" ? true : false
    
    console.log('Sending to test chatbot service:', combinedFormData, message, testconversation_openai_thread_id, clerk_user_id, isDivalogue);
    const testChatBotReply = await sendMessageToTestChatbotService({"personality": combinedFormData}, message, testconversation_openai_thread_id, clerk_user_id, isDivalogue);
    console.log(testChatBotReply)
    return testChatBotReply
  }, [isLoaded, generateCombinedFormData]);

  const testQualification = useCallback(async () => {
    const combinedFormData = await generateCombinedFormData();
    const idealClientProfile = combinedFormData.qualification.idealClientProfile;
    const testingICP = combinedFormData.qualification.testingICP;

  }, [generateCombinedFormData]);  

  return (
    <FormContext.Provider value={{inChatQualificationScore, setInChatQualificationScore, isQualified, setIsQualified, inChatQualificationResult, setInChatQualificationResult, qualificationResult, setQualificationResult, testQualification, isReplying, setIsReplying, currentThreadId, setCurrentThreadId, testConversationStep, setTestConversationStep, formData, nodes, edges, sendMessageToTestChatbot, deleteWorkflowStep, setInitialWorkflowSteps, setFormData, handleSave, saveFormData, updateFormData, workflowSteps, setWorkflowSteps, updateWorkflowStep, collectFormData }}>
      {children}
    </FormContext.Provider>
  );
};