import React from 'react';

const BotChatMessage = (props) => {
  return (
    <div className="botChatMessageBubble">
      {props.message}
    </div>
  );
};

export default BotChatMessage;
