// chakra imports
import { Box, Flex, Stack, Button, useColorModeValue, Heading, Text } from "@chakra-ui/react";
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import SidebarCard from "components/sidebar/components/SidebarCard";
import React, { useEffect } from "react";
import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import config from 'components/testchat/config.js';
import MessageParser from 'components/testchat/MessageParser.js';
import ActionProvider from 'components/testchat/ActionProvider.js';
import { requestThreadId } from '../../../service/service'; 
import { useFormContext } from '../../../contexts/FormContext';

const isDevelopment = process.env.REACT_APP_IS_DEVELOPMENT === "TRUE";

const setTestThreadId = async (setCurrentThreadId) => {
  const openai_thread_id = await requestThreadId();
  localStorage.setItem('test_thread_openai_id', openai_thread_id);
  setCurrentThreadId(openai_thread_id);
}

function SidebarContent(props) {
  const { routes } = props;
  const { testConversationStep, setCurrentThreadId, currentThreadId, inChatQualificationResult, isQualified, inChatQualificationScore } = useFormContext();

  const brandShadeColor = useColorModeValue('#3d0048', '#00324d');
  const brandColor = useColorModeValue('#3d0048', '#7bd1ff');
  const buttonTextColor = useColorModeValue('white', 'white');

  useEffect(() => {
    console.log(inChatQualificationResult);
  }, [inChatQualificationResult]);

  // SIDEBAR
  return (
    <Flex direction='column' height='100%' pt='25px' px="16px" borderRadius='30px'>
      <Brand />
        <Stack direction='column' mb='auto' mt='8px'>
          <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
            <Links routes={routes} />
          </Box>
        </Stack>
        <>
        <Flex flex="1" />
        <Flex mb="4" mt="32px">
          <Heading size="sm">Qualification:</Heading>
          <Heading size="sm" ml="8px">
            {isQualified.toString()}
          </Heading>
          
        </Flex>
        <Text>
            {inChatQualificationResult}
        </Text>
        <Text>
            {inChatQualificationScore}
        </Text>
        <Box>Current step: {testConversationStep}</Box>
        <Box fontSize="12px" mb="16px">Current thread_id: {currentThreadId}</Box>
        <Button 
          w="full" 
          borderColor={brandShadeColor}
          borderWidth={"1px"}
          color={buttonTextColor} 
          mb="16px" 
          onClick={() => setTestThreadId(setCurrentThreadId)}
          position="relative"
          overflow="hidden"
          transition="transform 0.2s ease-in-out"
          background={brandShadeColor}
          boxShadow={`0 0 0 1px ${brandShadeColor}`}
          _hover={{ 
              borderColor: brandColor,
              boxShadow: `0 0 0 2px ${brandShadeColor}`,
              background: brandColor,
          }}
        >
          New Thread
        </Button>
        <Box mb="16px">
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
          />
        </Box>
        </>
    </Flex>
  );
}

export default SidebarContent;
