import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  Input,
  NumberInput,
  NumberInputField,
  SimpleGrid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Textarea,
  VStack,
  useColorModeValue,
  Flex,
  Text,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogContent,
  Icon,
  Grid,
  useToast,
  Select,
  Checkbox
} from '@chakra-ui/react';

import { DeleteIcon } from '@chakra-ui/icons';
import ReactFlowWrapper from 'components/reactflow/ReactFlowWrapper';
import InstagramLogin from 'components/facebook/InstagramLogin';
import CalendlyLogin from 'components/calendly/CalendlyLogin';
import { getPersonalityData, fetchQualificationResult, loginWithInstagram, solveInstagramTwoFactorAuthentication } from '../../../service/service'; 
import { useFormContext } from '../../../contexts/FormContext'
import { useUser, useAuth } from '@clerk/clerk-react';

export default function UserReports() {
const { formData, updateFormData, saveFormData, setInitialWorkflowSteps, setFormData, testQualification, qualificationResult, setQualificationResult} = useFormContext();
const { isLoaded, user } = useUser();
const { getToken } = useAuth();
const [reactFlowData, setReactFlowData] = useState(null);
const brandColor = useColorModeValue('#3d0048', '#7bd1ff');
const isDivalogue = process.env.REACT_APP_PLATFORM === "DIVALOGUE"
const isDevelopment = process.env.REACT_APP_IS_DEVELOPMENT === "TRUE"

const [isAlertOpen, setIsAlertOpen] = useState(false);
const [deleteIndex, setDeleteIndex] = useState(null);
const [deleteArray, setDeleteArray] = useState(null);
const onClose = () => setIsAlertOpen(false);
const cancelRef = React.useRef();
const toast = useToast();

const [stageName, setStageName] = useState('');
const [stages, setStages] = useState([]);
const [rules, setRules] = useState([]);

// Function to handle adding new username input fields
const addUsernameInput = () => {
  const newUsernames = formData.usernames ? [...formData.usernames, ""] : [""];
  updateFormData('usernames', newUsernames); // Update formData directly
};

// Function to handle updating the username list when the user types
const handleUsernameChange = (index, event) => {
  const newUsernames = [...formData.usernames];
  newUsernames[index] = event.target.value;
  updateFormData('usernames', newUsernames); // Update formData directly
};

// Function to handle removing a username from the list
const removeUsernameInput = (index) => {
  const newUsernames = formData.usernames.filter((_, i) => i !== index);
  updateFormData('usernames', newUsernames); // Update formData directly
};

const addStage = () => {
  const newStage = {
    name: stageName,
    id: formData.conversationStages.stages.length + 1
  };

  let newRules = [...formData.conversationStages.rules]; // Clone the existing rules
  let newStages = [...formData.conversationStages.stages, newStage]; // Clone and add new stage

  // Add a rule when there's more than one stage
  if (newStages.length > 1) {
    const lastStageId = newStages[newStages.length - 2].id; // Get the last stage's ID
    const newRule = {
      initialStage: lastStageId,
      resultingStage: newStage.id,
      orderNumber: 0,
      transitionWhenLinkSent: false
    };
    newRules.push(newRule);
  }

  updateFormData('conversationStages', {
    stages: newStages,
    rules: newRules,
  });

  setStages(newStages); // Update local state
  setRules(newRules); // Update local state
  setStageName('');
};

useEffect(() => {
  if (formData.conversationStages) {
    setStages(formData.conversationStages.stages);
    setRules(formData.conversationStages.rules);
  }
}, [formData.conversationStages]);



const handleRuleChange = (index, field, value) => {
  // Clone the current rules array from the formData
  let updatedRules = [...formData.conversationStages.rules];

  // If the rule does not exist, create it
  if (!updatedRules[index]) {
    const newRule = {
      initialStage: stages[index]?.id,
      resultingStage: stages[index + 1]?.id,
      orderNumber: 0,
      transitionWhenLinkSent: false
    };
    updatedRules.push(newRule);
  }

  // Update the specific rule
  updatedRules = updatedRules.map((rule, i) => {
    if (rule.initialStage === stages[index]?.id && rule.resultingStage === stages[index + 1]?.id) {
      return { ...rule, [field]: value };
    }
    return rule;
  });

  // Update the rules in both local state and formData state
  setRules(updatedRules);
  updateFormData('conversationStages', {
    ...formData.conversationStages,
    rules: updatedRules,
  });
};

const handleCheckboxChange = (index) => {
  // Clone the current rules array from the formData
  let updatedRules = [...formData.conversationStages.rules];

  // Initialize the rule at the index if it doesn't exist
  if (!updatedRules[index]) {
    updatedRules[index] = {
      initialStage: stages[index]?.id,
      resultingStage: stages[index + 1]?.id,
      orderNumber: 0,
      transitionWhenLinkSent: false
    };
  }

  // Update the transitionWhenLinkSent property for all rules
  updatedRules = updatedRules.map((rule, i) => {
    if (rule.initialStage === stages[index]?.id && rule.resultingStage === stages[index + 1]?.id) {
      return { ...rule, transitionWhenLinkSent: !rule.transitionWhenLinkSent };
    }
    return rule;
  });

  // Update the rules in both local state and formData state
  setRules(updatedRules);
  updateFormData('conversationStages', {
    ...formData.conversationStages,
    rules: updatedRules,
  });
};

const confirmDeleteStage = (index) => {
  setDeleteArray('stages');
  setDeleteIndex(index);
  setIsAlertOpen(true);
};

// Add a function to delete a stage
const deleteStage = (index) => {
  const newStages = formData.conversationStages.stages.filter((_, i) => i !== index);
  const newRules = formData.conversationStages.rules.filter(rule => rule.initialStage !== newStages[index]?.id && rule.resultingStage !== newStages[index]?.id);

  updateFormData('conversationStages', {
    stages: newStages,
    rules: newRules,
  });

  onClose();
};

const SuccessToast = () => (
  <Box color="white" p={3} bg="#28a745" borderRadius="md" minW="250px">
    Data saved successfully.
  </Box>
);

const ErrorToast = ({ message }) => (
  <Box color="white" p={3} bg="#dc3545" borderRadius="md" minW="250px">
    Error: {message}
  </Box>
);

const confirmDelete = (index, deleteArray) => {
  if (deleteArray === 'idealClientProfile' && formData.qualification.idealClientProfile[index].key === '' && formData.qualification.idealClientProfile[index].value === '') {
    deleteProfilePair(index);
  } else {
    setDeleteArray(deleteArray);
    setDeleteIndex(index);
    setIsAlertOpen(true);
  }
};

const deleteProfilePair = (index) => {
  const newProfile = formData.qualification.idealClientProfile.filter((_, i) => i !== index);
  const newTestingICP = formData.qualification.testingICP.filter((_, i) => i !== index);
  console.log(newProfile)
  updateFormData('qualification',  { idealClientProfile: newProfile });
  updateFormData('qualification',  { testingICP: newTestingICP });
  onClose();
};

const handleLoginSubmit = async (e) => {
  e.preventDefault();
  const form = e.target;
  
  const username = form.instagram_username.value;
  const password = form.instagram_password.value;
  const userEmail = user?.primaryEmailAddress.emailAddress;

  console.log('Logging in with Instagram...');

  if (!username || !password) {
    console.error('Username or password is missing');
    return;
  }

  try {
    console.log("deviceProfile", formData.deviceProfile)
    const loginResponse = await loginWithInstagram(username, password, userEmail, formData.deviceProfile);

    if (loginResponse.message === '2FA code required') {
      // The response now includes 'client_session_id', which needs to be passed for 2FA verification
      const clientSessionId = loginResponse.client_session_id; // Capture the client_session_id

      // Prompt the user for the 2FA code using an alert box
      const twoFactorCode = prompt('Please enter your 2FA code:');
      
      if (twoFactorCode) {
        // Include clientSessionId in the deviceProfile when calling solveInstagramTwoFactorAuthentication
        const updatedDeviceProfile = { ...formData.deviceProfile, client_session_id: clientSessionId };
        
        const twoFactorResponse = await solveInstagramTwoFactorAuthentication(username, twoFactorCode, userEmail, updatedDeviceProfile);
        console.log(twoFactorResponse);
        
        if (twoFactorResponse.message === 'Challenge resolved successfully') {
          // Handle successful login after 2FA resolution
          console.log('2FA resolved and login successful');
          toast({
            position: 'bottom',
            render: () => <SuccessToast />,
          });
        }
      }
    } else {
      console.log('Login successful without needing 2FA');
      form.instagram_username.value = '';
      form.instagram_password.value = '';
      toast({
        position: 'bottom',
        render: () => <SuccessToast />,
      });
    }
  } catch (error) {
    console.error('Error during the login process:', error);
    toast({
      position: 'bottom-left',
      render: () => <ErrorToast message={error.message} />,
    });
  }
};


useEffect(() => {
  if (formData.conversationStages) {
    // Sort stages by their id in ascending order before setting them
    const sortedStages = [...formData.conversationStages.stages].sort((a, b) => a.id - b.id);
    setStages(sortedStages);
    setRules(formData.conversationStages.rules);
  }
}, [formData.conversationStages]);


const addProfilePair = () => {
  if (Array.isArray(formData.qualification.idealClientProfile) && Array.isArray(formData.qualification.testingICP)) {
    const newProfile = [...formData.qualification.idealClientProfile, { key: "", value: "" }];
    const newTestingICP = [...formData.qualification.testingICP, { key: "", value: "" }];
    updateFormData('qualification',  { idealClientProfile: newProfile });
    updateFormData('qualification', { testingICP: newTestingICP });
  } else {
    updateFormData('qualification', {idealClientProfile: [{ key: "", value: "" }]});
    updateFormData('qualification', {testingICP: [{ key: "", value: "" }]});
  }
};

const addFollowUpToStage = (stageId) => {
  const newFollowUp = {
    id: Date.now(), // Simple unique ID generator
    message_type: '',
    message_content: '',
    voice_message_name: '',
    comment_content: '',
    manual_story_message_contnent: '',
    manual_chat_message_content: '',
    intelligent_text_prompt: '',
    intelligent_manual_story_message_prompt: '',
    intelligent_manual_chat_message_prompt: '',
    intelligent_comment_prompt: '',
    send_after_this_many_hours_since: 0,
    reaction_required: false,
    read_required: false,
  };

  // Assuming formData has a structure where each stage in conversationStages.stages has a followUps array
  const updatedStages = formData.conversationStages.stages.map(stage => {
    if (stage.id === stageId) {
      return {
        ...stage,
        followUps: [...(stage.followUps || []), newFollowUp],
      };
    }
    return stage;
  });

  updateFormData('conversationStages', { ...formData.conversationStages, stages: updatedStages });
};

const deleteFollowUpFromStage = (stageId, followUpIndex) => {
  const updatedStages = formData.conversationStages.stages.map(stage => {
    if (stage.id === stageId) {
      // Use the index to filter out the follow-up
      const updatedFollowUps = stage.followUps.filter((_, index) => index !== followUpIndex);
      return { ...stage, followUps: updatedFollowUps };
    }
    return stage;
  });

  updateFormData('conversationStages', { ...formData.conversationStages, stages: updatedStages });
};


const handleFollowUpChange = (stageId, followUpId, field, value) => {
  const updatedStages = formData.conversationStages.stages.map(stage => {
    if (stage.id === stageId) {
      return {
        ...stage,
        followUps: stage.followUps.map(followUp => {
          if (followUp.id === followUpId) {
            return { ...followUp, [field]: value };
          }
          return followUp;
        }),
      };
    }
    return stage;
  });

  updateFormData('conversationStages', { ...formData.conversationStages, stages: updatedStages });
};

const handleProfileChange = (index, field) => (e) => {
  if (Array.isArray(formData.qualification.idealClientProfile) && Array.isArray(formData.qualification.testingICP)) {
    const newProfile = [...formData.qualification.idealClientProfile];
    const newTestingICP = [...formData.qualification.testingICP];

    newProfile[index][field] = e.target.value;
    if (field === 'key') {
      newTestingICP[index].key = e.target.value;
    }

    updateFormData('qualification',  { idealClientProfile: newProfile });
    updateFormData('qualification', { testingICP: newTestingICP });
  } else {
    console.error('idealClientProfile or testingICP is not an array');
  }
};

const handleTestingICPChange = (index) => (e) => {
  if (Array.isArray(formData.qualification.testingICP)) {
    const newTestingICP = [...formData.qualification.testingICP];
    newTestingICP[index].value = e.target.value;
    updateFormData('qualification', { testingICP: newTestingICP });
  } else {
    console.error('testingICP is not an array');
  }
};

const formatJson = (json) => {
  return JSON.stringify(json, null, 2); // Indent with 2 spaces
};

const JsonDisplay = ({ jsonData }) => {
  if (!jsonData) return null;

  return (
    <pre style={{ padding: "15px", borderRadius: "10px", overflowX: "auto"}}>
      {formatJson(jsonData)}
    </pre>
  );
};


const generateQualificationResult = async (idealClientProfile, testingICP, additional_instructions_for_qualification) => {
  console.log("idealClientProfile", idealClientProfile)
  console.log("testingICP", testingICP)
  const qualificationResult = await fetchQualificationResult(idealClientProfile, testingICP, additional_instructions_for_qualification);
  console.log("qualificationResult", qualificationResult)
  setQualificationResult(qualificationResult);

  return qualificationResult;
}

const addKeyword = () => {
  if (Array.isArray(formData.humanEscalation.keywords)) {
    const newKeywords = [...formData.humanEscalation.keywords, ""];
    updateFormData('humanEscalation', { keywords: newKeywords });
  } else {
    // Initialize keywords as an array if it's not
    updateFormData('humanEscalation', { keywords: [""] });
  }
};

// Handle changes to the keyword list
const handleKeywordChange = (index) => (e) => {
  const newKeywords = [...formData.humanEscalation.keywords];
  newKeywords[index] = e.target.value;
  updateFormData('humanEscalation', { keywords: newKeywords });
};

  useEffect(() => {
	const fetchPersonalityData = async () => {
    console.log(formData)
		try {
			const token = await getToken();
			const email = user?.primaryEmailAddress.emailAddress;
		  const personalityData = await getPersonalityData(email, token);
		  setInitialWorkflowSteps(personalityData.conversationStrategy.steps);
	
		  // Logic for transforming fetched data into React Flow's format
		  let lastStepId = "0"; // Initialize the ID for the root node.
		  const nodes = personalityData.conversationStrategy.steps.map((step, index) => {
			const nodeId = String(index + 1);

			if (index === 0) {
			  // First node is assumed to be the root, with the parent ID set to null.
			  return {
				id: nodeId,
				type: 'workflow', // Assuming 'workflow' is a valid node type.
				data: {
				  stepName: step.stepName,
				  stepGoal: step.stepGoal,
				  stepOrder: step.stepOrder,
				  stepSpecificBehaviour: step.stepSpecificBehaviour,
          stepCriteria: step.stepCriteria,
          stepEncourageEnd: parseInt(step.stepEncourageEnd),
          stepEncourageStart: parseInt(step.stepEncourageStart),
				  isBasePersonalityIgnored: step.isBasePersonalityIgnored,
				},
				position: { x: 0, y: index * 100 }, // Example position, adjust as needed.
				parentNode: null, // No parent for the root node.
				extent: "root"
			  };
			} else {
			  lastStepId = nodeId; // Update the ID for the last node.
			  return {
				id: nodeId,
				type: 'workflow', // Assuming 'workflow' is a valid node type.

				data: {
					stepName: step.stepName,
					stepGoal: step.stepGoal,
					stepOrder: step.stepOrder,
					stepSpecificBehaviour: step.stepSpecificBehaviour,
          stepCriteria: step.stepCriteria,
          stepEncourageEnd: parseInt(step.stepEncourageEnd),
          stepEncourageStart: parseInt(step.stepEncourageStart),
          isBasePersonalityIgnored: step.isBasePersonalityIgnored,
				},
				position: { x: 0, y: index * 100 }, // Example position, adjust as needed.
				parentNode: String(index) // Parent is the previous step.
			  };
			}
		  });
	  
		  // Creating edges from nodes.
		  const edges = nodes.slice(1).map((node, index) => ({
			id: `e${nodes[index].id}-${node.id}`,
			source: nodes[index].id, // The source is the previous node's ID
			target: node.id,
			type: 'workflow', // Use your custom placeholder edge type
		  }));

		  const placeholderNodeId = "placeholder";
const lastNodeId = nodes[nodes.length - 1].id;

// Add placeholder node
nodes.push({
  id: placeholderNodeId,
  type: 'placeholder',
  data: { label: '+' },
  position: { x: 0, y: nodes.length * 100 }, // Adjust position as needed
  parentNode: lastNodeId
});

// Add edge from the last node to the placeholder node
edges.push({
  id: `e${lastNodeId}-${placeholderNodeId}`,
  source: lastNodeId,
  target: placeholderNodeId,
  type: 'placeholder', // Use your custom placeholder edge type
});
	  
		  setReactFlowData({ initialNodes: nodes, initialEdges: edges });
			setFormData(personalityData); // Assumes that setFormData will replace the entire form data object
		} catch (error) {
		  console.error('Failed to fetch personality data:', error);
		  // Handle the error appropriately - show a notification to the user, etc.
		}
	  };
	  if (isLoaded) {
		fetchPersonalityData();
	  }
  }, [isLoaded, setInitialWorkflowSteps]); 


  const handleChange = (section, field) => (e, value) => {
    const newValue = field === 'age' || field === "min_qualification_score" || field === "qualify_at_message_nr" ? value : e.target.value;
  
    updateFormData(section, { [field]: newValue });
  };

const sharedFormControlProps = {
    mt: 4,
    alignItems: 'center',
  };

  const inputTextColor = useColorModeValue('black', 'white'); 

  const inputStyles = {
    _focus: {
      borderColor: brandColor,
      boxShadow: `0 0 0 1px ${brandColor}`,
    },
    color: inputTextColor
  };

  const tabStyles = {
    borderBottomColor: 'transparent',
    _selected: {
      color: brandColor,
      borderBottomColor: brandColor,
    },
    _focus: {
      zIndex: 1,
      boxShadow: `0 0 0 1px ${brandColor}`,
    },
  };

  const brandShadeColor = useColorModeValue('#3d0048', '#00324d');
  // Get text color for the button based on the light or dark mode
  const buttonTextColor = useColorModeValue('white', 'white');

  const deleteButtonStyles = {
    _hover: {
      transform: 'scale(1.1)',
    },
    transition: 'transform 0.2s ease-in-out', // Smooth transition for transform
  };
  
  const buttonStyles = {
    backgroundColor: brandShadeColor,
    color: buttonTextColor,
    fontSize: "14px",
    _hover: {
      bg: brandColor,
    },
    _focus: {
      boxShadow: `0 0 0 1px ${brandColor}`,
    },
  };

  return (
    <>
    <AlertDialog
      isOpen={isAlertOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            Delete Item
          </AlertDialogHeader>

          <AlertDialogBody>
          Are you sure you want to delete this {deleteArray === 'stages' ? 'stage' : 'profile pair'}? You can't undo this action afterwards.
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button ref={cancelRef} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={() => deleteArray === 'stages' ? deleteStage(deleteIndex) : deleteProfilePair(deleteIndex)} ml={3}>
            Delete
          </Button>
        </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
    <Box display="flex" h="88vh" mt="12vh" gap="32px">
      <Box w="50%">

	{reactFlowData && (
	  <ReactFlowWrapper
            initialNodes={reactFlowData.initialNodes}
            initialEdges={reactFlowData.initialEdges}
          />
	)}
      </Box>
      <Box w="50%" display="flex" flexDirection="column" overflowY="auto" p="4px">
	  <Box flex="1" display="flex" gap="32px" overflowX="hidden">
      <Tabs isFitted variant="enclosed" w="100%" colorScheme="brand" overflowX="hidden">
          <TabList mb="1em"  style={{height: "64px"}}>
            <Tab {...tabStyles}>Base Personality</Tab>
            <Tab {...tabStyles}>Style and Strategy</Tab>
            <Tab {...tabStyles}>Identity</Tab>
            <Tab {...tabStyles}>Conversation Stages</Tab>
            <Tab {...tabStyles}>Qualification</Tab>
            {isDevelopment && <Tab {...tabStyles}>Examples</Tab> }
            {/* { !isDivalogue &&  <Tab {...tabStyles}>Human Escalation</Tab> } */}
            {/* <Tab {...tabStyles}>Post-processsing</Tab> */}
            <Tab {...tabStyles}>Integrations</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <VStack spacing={4}>
                <Box w="full" borderRadius="lg">
              <Heading size="md">Base Personality</Heading>
              <Divider my={5} />
              <FormControl>
                    <FormLabel>Link</FormLabel>
                    <Input
                    {...inputStyles}
                    size="sm"
                      placeholder="The link that the bot aims to send the prospect to ..."
                      name="link"
                      value={formData.basePersonality.link}
                      onChange={(e) => handleChange('basePersonality', 'link')(e)}
                    />
              </FormControl>
              { !isDivalogue &&  <FormControl {...sharedFormControlProps}>
                <FormLabel>Product Info</FormLabel>
                <Textarea
                 {...inputStyles}
                  placeholder="Information about the product that the bot should know ..."
                  size="sm"
                  minH="8rem"
                  name="product_knowledge_text"
                  value={formData.basePersonality.product_knowledge_text}
                  onChange={handleChange('basePersonality', 'product_knowledge_text')}
                />
              </FormControl>}
			  <FormControl {...sharedFormControlProps}>
                <FormLabel>General Knowledge</FormLabel>
                <Textarea
                {...inputStyles}
                  placeholder="Any general knowledge that the bot should be aware of ..."
                  size="sm"
                  minH={isDivalogue ? "32rem" : "8rem"}
                  name="general_knowledge_text"
                  value={formData.basePersonality.general_knowledge_text}
                  onChange={handleChange('basePersonality', 'general_knowledge_text')}
                />
              </FormControl>
              { !isDivalogue && <FormControl {...sharedFormControlProps}>
                <FormLabel>Goal(s)</FormLabel>
                <Textarea
                {...inputStyles}
                  placeholder="Describe the goal(s) of the conversation agent (to send a link, etc)..."
                  size="sm"
                  minH="8rem"
                  name="goals"
                  value={formData.basePersonality.goals}
                  onChange={handleChange('basePersonality', 'goals')}
                />
              </FormControl>}
            </Box>
              </VStack>
            </TabPanel>
            <TabPanel>
              <VStack spacing={4}>

            <Box w="full" borderRadius="lg">
              <Heading size="md">Style and Strategy</Heading>
              <Divider my={5} />
              <FormControl {...sharedFormControlProps}>
			  <FormControl {...sharedFormControlProps}>
                <FormLabel w="30%">General Communication Style Guidelines</FormLabel>
                <Textarea
                {...inputStyles}
                  placeholder="Describe the desired general communication style of the bot ..."
                  size="sm"
                  minH="8rem"
                  name="general_style_description"
                  value={formData.communicationStyle.general_style_description}
                  onChange={handleChange('communicationStyle', 'general_style_description')}
                />
              </FormControl>
              <FormControl {...sharedFormControlProps}>
                <FormLabel w="30%">Topics to avoid discussing</FormLabel>
                <Textarea
                {...inputStyles}
                  placeholder="Describe topics that the bot should tactfully avoid discussing / responding to ..."
                  size="sm"
                  minH="8rem"
                  name="dont_respond_to_description"
                  value={formData.communicationStyle.dont_respond_to_description}
                  onChange={handleChange('communicationStyle', 'dont_respond_to_description')}
                />
              </FormControl>
			  <FormControl {...sharedFormControlProps}>
                <FormLabel w="30%">Instructions for Step Selection Assistant</FormLabel>
                <Textarea
                {...inputStyles}
                  placeholder="Additional Instructions for the Step Selection Assistant  ..."
                  size="sm"
                  minH="8rem"
                  name="instructions_for_step_selection_assistant"
                  value={formData.conversationStrategy.instructions_for_step_selection_assistant}
                  onChange={handleChange('conversationStrategy', 'instructions_for_step_selection_assistant')}
                />
				</FormControl>
              </FormControl>
            </Box>
              </VStack>
            </TabPanel>
            <TabPanel>
			<Box w="full" borderRadius="lg">
			<Heading size="md">Identity</Heading>
              <Divider my={5} />
              <FormControl {...sharedFormControlProps}>
              <VStack spacing={4}>
                <SimpleGrid columns={[1, 2, 3]} spacing={4} w="full">
                  <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input
                    {...inputStyles}
                      placeholder="Bot's Name"
                      name="name"
                      value={formData.personalIdentity.name}
                      onChange={(e) => handleChange('personalIdentity', 'name')(e)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Age</FormLabel>
                    <NumberInput min={0} onChange={(valueString) => handleChange('personalIdentity', 'age')(null, valueString)} value={formData.personalIdentity.age != 0 ? formData.personalIdentity.age : ""}>
                      <NumberInputField
                      {...inputStyles}
                        placeholder="Age"
                        name="age"       
                      />
                    </NumberInput>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Gender</FormLabel>
                    <Input
                    {...inputStyles}
                      placeholder="Gender"
                      name="gender"
                      value={formData.personalIdentity.gender}
                      onChange={(e) => handleChange('personalIdentity', 'gender')(e)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Pronouns</FormLabel>
                    <Input
                    {...inputStyles}
                      placeholder="Pronouns"
                      name="pronouns"
                      value={formData.personalIdentity.pronouns}
                      onChange={(e) => handleChange('personalIdentity', 'pronouns')(e)}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Origin</FormLabel>
                    <Input
                    {...inputStyles}
                      placeholder="Origin"
                      name="origin"
                      value={formData.personalIdentity.origin}
                      onChange={(e) => handleChange('personalIdentity', 'origin')(e)}
                    />
                  </FormControl>
                  {/* <FormControl>
                    <FormLabel>Race</FormLabel>
                    <Input
                    {...inputStyles}
                      placeholder="Race"
                      name="race"
                      value={formData.personalIdentity.race}
                      onChange={(e) => handleChange('personalIdentity', 'race')(e)}
                    />
                  </FormControl> */}
                  <FormControl>
                    <FormLabel>Profession</FormLabel>
                    <Input
                    {...inputStyles}
                      placeholder="Profession"
                      name="profession"
                      value={formData.personalIdentity.profession}
                      onChange={(e) => handleChange('personalIdentity', 'profession')(e)}
                    />
                  </FormControl>
                </SimpleGrid>
                <FormControl>
                  <FormLabel>Hobbies</FormLabel>
                  <Textarea
                  {...inputStyles}
				  	        minH="5rem"
                    size="sm"
                    placeholder="Hobbies"
                    name="hobbies"
                    value={formData.personalIdentity.hobbies}
                    onChange={(e) => handleChange('personalIdentity', 'hobbies')(e)}
                  />
                </FormControl>
                {/* <FormControl>
                  <FormLabel>Skills</FormLabel>
                  <Textarea
				  	minH="5rem"
                    placeholder="Skills"
                    name="skills"
                    value={formData.personalIdentity.skills}
                    onChange={(e) => handleChange('personalIdentity', 'skills')(e)}
                  />
                </FormControl> */}
                <FormControl>
                  <FormLabel>Values & Motivations</FormLabel>
                  <Textarea
                  {...inputStyles}
				  	        minH="5rem"
                    size="sm"
                    placeholder="Values & Motivations"
                    name="values_motivations"
                    value={formData.personalIdentity.values_motivations}
                    onChange={(e) => handleChange('personalIdentity', 'values_motivations')(e)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>General Facts</FormLabel>
                  <Textarea
                  {...inputStyles}
				  	        minH="5rem"
                    size="sm"
                    placeholder="Other general facts about the bot's past / personality etc ..."
                    name="general_facts"
                    value={formData.personalIdentity.general_facts}
                    onChange={(e) => handleChange('personalIdentity', 'general_facts')(e)}
                  />
                </FormControl>
              </VStack>
			  </FormControl>
			  </Box>
            </TabPanel>
            <TabPanel>
              <VStack spacing={4}>
                <Box w="full" borderRadius="lg">
                  <Heading size="md">Conversation Stages</Heading>
                  <Divider my={5} />
                  <FormControl {...sharedFormControlProps}>
                    <FormLabel>Stage Name</FormLabel>
                    <Input
                      {...inputStyles}
                      placeholder="Enter stage name..."
                      size="sm"
                      name="stageName"
                      value={stageName}
                      onChange={(e) => setStageName(e.target.value)}
                    />
                  </FormControl>
                  <Button mt="16px" onClick={addStage} {...buttonStyles}>Add Stage</Button>
                  {stages.map((stage, index) => (
                    <VStack key={stage.id} spacing={4} mt={4} align="stretch">
                      <Flex justify="space-between" w="full">
                        <Box p={4} pl={0}>
                          <Heading size="md" fontWeight="bold">{stage.name}</Heading>
                        </Box>
                        <Button colorScheme="red" onClick={() => confirmDeleteStage(index)}>
                          Delete
                        </Button>
                      </Flex>
                      {index < stages.length - 1 && (
                        <>
                          <FormControl>
                            <FormLabel>Transition to "{stages[index + 1]?.name}" at msg nr:</FormLabel>
                            <SimpleGrid columns={2} spacing={4}>
                              <NumberInput
                                placeholder="Order number..."
                                size="sm"
                                value={rules.find(r => r.initialStage === stage.id && r.resultingStage === stages[index + 1]?.id)?.orderNumber || ''}
                                onChange={(valueString) => handleRuleChange(index, 'orderNumber', valueString)}
                                isDisabled={rules.find(r => r.initialStage === stage.id && r.resultingStage === stages[index + 1]?.id)?.transitionWhenLinkSent}
                              >
                                <NumberInputField
                                {...inputStyles} />
                              </NumberInput>
                              <Checkbox
                                isChecked={rules.find(r => r.initialStage === stage.id && r.resultingStage === stages[index + 1]?.id)?.transitionWhenLinkSent || false}
                                onChange={() => handleCheckboxChange(index)}
                              >
                                Transition when link sent
                              </Checkbox>
                            </SimpleGrid>
                          </FormControl>
                        </>
                      )}
                      <Button onClick={() => addFollowUpToStage(stage.id)} size="sm">+ Add Follow-Up</Button>
                      <VStack spacing={2} w="full">
                        {stage.followUps && stage.followUps.map((followUp, fIndex) => (
                          <Flex key={fIndex} p={2} border="1px solid" borderColor="gray.200" borderRadius="md" alignItems="center" justifyContent="space-between" flexWrap="wrap">
                            <FormControl size="sm" flex="1" minW="240px" mr={2}>
                              <FormLabel>Message Type</FormLabel>
                              <Select value={followUp.message_type} onChange={(e) => handleFollowUpChange(stage.id, followUp.id, 'message_type', e.target.value)}>
                                <option value="">Select Type</option>
                                <option value="text">Text</option>
                                <option value="voice">Voice</option>
                                <option value="manual_story_message">Manual Story Message</option>
                                <option value="manual_chat_message">Manual Chat Message</option>
                                <option value="comment">Comment</option>
                                <option value="intelligent_text">Intelligent Text</option>
                                <option value="intelligent_manual_story_message">Intelligent Manual Story Message</option>
                                <option value="intelligent_manual_chat_message">Intelligent Manual Chat Message</option>
                                <option value="intelligent_comment">Intelligent Comment</option>
                              </Select>
                            </FormControl>
                            {followUp.message_type === 'text' && (
                            <FormControl size="sm" flex="2" minW="240px" mr={2}>
                              <FormLabel>Message Content</FormLabel>
                              <Input {...inputStyles} value={followUp.message_content} onChange={(e) => handleFollowUpChange(stage.id, followUp.id, 'message_content', e.target.value)} />
                            </FormControl>
                            )}
                            {followUp.message_type === 'voice' && (
                              <FormControl size="sm" flex="1" minW="240px" mr={2}>
                                <FormLabel>Voice Message Name</FormLabel>
                                <Input {...inputStyles} value={followUp.voice_message_name} onChange={(e) => handleFollowUpChange(stage.id, followUp.id, 'voice_message_name', e.target.value)} />
                              </FormControl>
                            )}
                            {followUp.message_type === 'manual_story_message' && (
                              <FormControl size="sm" flex="1" minW="240px" mr={2}>
                                <FormLabel>Manual Story Message Content</FormLabel>
                                <Input {...inputStyles} value={followUp.manual_story_message_content} onChange={(e) => handleFollowUpChange(stage.id, followUp.id, 'manual_story_message_content', e.target.value)} />
                              </FormControl>
                            )}
                            {followUp.message_type === 'manual_chat_message' && (
                              <FormControl size="sm" flex="1" minW="240px" mr={2}>
                                <FormLabel>Manual Chat Message Content</FormLabel>
                                <Input {...inputStyles} value={followUp.manual_chat_message_content} onChange={(e) => handleFollowUpChange(stage.id, followUp.id, 'manual_chat_message_content', e.target.value)} />
                              </FormControl>
                            )}
                            {followUp.message_type === 'comment' && (
                              <FormControl size="sm" flex="1" minW="240px" mr={2}>
                                <FormLabel>Comment Content</FormLabel>
                                <Input {...inputStyles} value={followUp.comment_content} onChange={(e) => handleFollowUpChange(stage.id, followUp.id, 'comment_content', e.target.value)} />
                              </FormControl>
                            )}
                            {followUp.message_type === 'intelligent_text' && (
                              <FormControl size="sm" flex="1" minW="240px" mr={2}>
                                <FormLabel>Intelligent Text Prompt</FormLabel>
                                <Input {...inputStyles} value={followUp.intelligent_text_prompt} onChange={(e) => handleFollowUpChange(stage.id, followUp.id, 'intelligent_text_prompt', e.target.value)} />
                              </FormControl>
                            )}
                            {followUp.message_type === 'intelligent_manual_story_message' && (
                              <FormControl size="sm" flex="1" minW="240px" mr={2}>
                                <FormLabel>Intelligent Manual Story Message Prompt</FormLabel>
                                <Input {...inputStyles} value={followUp.intelligent_manual_story_message_prompt} onChange={(e) => handleFollowUpChange(stage.id, followUp.id, 'intelligent_manual_story_message_prompt', e.target.value)} />
                              </FormControl>
                            )}
                            {followUp.message_type === 'intelligent_manual_chat_message' && (
                              <FormControl size="sm" flex="1" minW="240px" mr={2}>
                                <FormLabel>Intelligent Manual Chat Message Prompt</FormLabel>
                                <Input {...inputStyles} value={followUp.intelligent_manual_chat_message_prompt} onChange={(e) => handleFollowUpChange(stage.id, followUp.id, 'intelligent_manual_chat_message_prompt', e.target.value)} />
                              </FormControl>
                            )}
                            {followUp.message_type === 'intelligent_comment' && (
                              <FormControl size="sm" flex="1" minW="240px" mr={2}>
                                <FormLabel>Intelligent Comment Prompt</FormLabel>
                                <Input {...inputStyles} value={followUp.intelligent_comment_prompt} onChange={(e) => handleFollowUpChange(stage.id, followUp.id, 'intelligent_comment_prompt', e.target.value)} />
                              </FormControl>
                            )}
                            <FormControl size="sm" flex="1" minW="120px" mr={2}>
                              <FormLabel>Send After (hours)</FormLabel>
                              <NumberInput value={followUp.send_after_this_many_hours_since} onChange={(valueString) => handleFollowUpChange(stage.id, followUp.id, 'send_after_this_many_hours_since', parseFloat(valueString))}>
                                <NumberInputField 
                                {...inputStyles}/>
                              </NumberInput>
                            </FormControl>
                            <FormControl display="flex" alignItems="center" flex="1" minW="120px" mr={2}>
                              <FormLabel mb="0">Reaction Required?</FormLabel>
                              <Checkbox isChecked={followUp.reaction_required} onChange={(e) => handleFollowUpChange(stage.id, followUp.id, 'reaction_required', e.target.checked)} />
                            </FormControl>
                            <FormControl display="flex" alignItems="center" flex="1" minW="120px">
                              <FormLabel mb="0">Read Required?</FormLabel>
                              <Checkbox isChecked={followUp.read_required} onChange={(e) => handleFollowUpChange(stage.id, followUp.id, 'read_required', e.target.checked)} />
                            </FormControl>
                            <Button size="sm" colorScheme="red" onClick={() => deleteFollowUpFromStage(stage.id, fIndex)} ml={2}>Delete Follow-Up</Button>
                          </Flex>
                        ))}
                      </VStack>
                    </VStack>
                  ))}
                </Box>
              </VStack>
            </TabPanel>
           <TabPanel>
              <VStack spacing={4}>
                <Box w="full" borderRadius="lg">
                <Heading size="md" mb="4">Qualification Configuration</Heading>
                <VStack spacing={4} mb="5">
                  <Flex alignItems="flex-end" w="100%" gap="16px">
                    <FormControl flex="17">
                      <FormLabel>Additional Instructions for Qualification</FormLabel>
                      <Textarea
                        placeholder="Enter addtitional instructions for qualification ..." 
                        value={formData.qualification.additional_instructions_for_qualification} 
                        onChange={handleChange('qualification', 'additional_instructions_for_qualification')}
                      />
                    </FormControl>
                    <FormControl flex="3">
                      <FormLabel>Min. Qualification Score</FormLabel>
                      <NumberInput 
                        {...inputStyles}
                        value={formData.qualification.min_qualification_score} 
                        onChange={handleChange('qualification', 'min_qualification_score')}
                      >
                        <NumberInputField {...inputStyles} />
                      </NumberInput>
                    </FormControl>
                    <FormControl flex="3">
                      <FormLabel>Qualify at message nr</FormLabel>
                      <NumberInput 
                        {...inputStyles}
                        value={formData.qualification.qualify_at_message_nr} 
                        onChange={handleChange('qualification', 'qualify_at_message_nr')}
                      >
                        <NumberInputField {...inputStyles} />
                      </NumberInput>
                    </FormControl>
                  </Flex>
                </VStack>
                  <Flex justifyContent="space-between" alignItems="center" gap="16px">
                    <Heading size="md">Ideal Client Profile Definition</Heading>
                    <Button onClick={addProfilePair} {...buttonStyles}>Add Another Pair</Button>
                  </Flex>
                  <Divider my={5} />
                  <VStack spacing={4}>
                    {Array.isArray(formData.qualification.idealClientProfile) && formData.qualification.idealClientProfile.map((pair, index) => (
                      <Grid templateColumns="12fr 1fr 20fr" gap={2} key={index} alignItems="center" w="100%">
                        <Input 
                          {...inputStyles}
                          placeholder="Key (e.g., age)" 
                          value={pair.key} 
                          onChange={handleProfileChange(index, 'key')} 
                        />
                        <Text fontSize="lg" lineHeight="1.2" mr="3" ml="0" textAlign="center">:</Text>
                        <Input 
                          {...inputStyles}
                          placeholder="Value" 
                          value={pair.value} 
                          onChange={handleProfileChange(index, 'value')}
                        />
                        <Icon 
                          gridRow="1 / span 2"
                          gridColumn="4"
                          {...deleteButtonStyles} 
                          as={DeleteIcon} 
                          onClick={() => confirmDelete(index, 'idealClientProfile')} 
                          cursor="pointer" 
                          w="16px"
                          mb="10px"
                        />
                      </Grid>
                    ))}
                  </VStack>
                  <Divider my={5} />
                  <Flex justifyContent="space-between" alignItems="center" gap="16px" mb="16px">
                    <Heading size="sm">Test values:</Heading>
                    <Button onClick={() => generateQualificationResult(formData.qualification.idealClientProfile, formData.qualification.testingICP)} {...buttonStyles}>Test qualification</Button>
                  </Flex>
                  <VStack spacing={4} mr="20px">
                    {Array.isArray(formData.qualification.idealClientProfile) && formData.qualification.idealClientProfile.map((pair, index) => (
                      <Grid templateColumns="12fr 1fr 20fr" gap={2} key={index} alignItems="center" w="100%">
                        <Text flex="1">{pair.key}</Text>
                        <Text fontSize="lg" lineHeight="1.2" mr="3" ml="0" textAlign="center">:</Text>
                        <Input 
                          {...inputStyles}
                          placeholder="Value" 
                          value={formData.qualification.testingICP[index].value || ''}
                          onChange={handleTestingICPChange(index, 'value')}
                        />
                      </Grid>
                    ))}
                  </VStack>
                  <Divider my={5} />
                  <Flex mb="4" mt="32px">
                    <Heading size="md">Qualification Result:</Heading>
                    <Heading size="md" ml="8px">
                        {qualificationResult && qualificationResult.qualification_score_response
                            ? qualificationResult.qualification_score_response.combined_result.ideal_client_score
                            : ""}
                    </Heading>
                  </Flex>
                  {qualificationResult && qualificationResult.qualification_score_response && (
                    <JsonDisplay jsonData={qualificationResult.qualification_score_response} />
                  )}
                </Box>
              </VStack>
            </TabPanel>
            { isDevelopment && <TabPanel>
              <VStack spacing={4}>
                <Box w="full" borderRadius="lg">
              <Heading size="md">Behaviour examples</Heading>
              <Divider my={5} />
              <FormControl {...sharedFormControlProps}>
                <FormLabel>Behaviour examples</FormLabel>
                <Textarea
                {...inputStyles}
                  placeholder="Examples of how the bot should behave / talk ..."
                  size="sm"
                  minH="32rem"
                  name="behaviours_example"
                  value={formData.behaviourExample.behaviour_example}
                  onChange={(e) => {handleChange('behaviourExample', 'behaviour_example')(e)}}
                />
              </FormControl>
            </Box>
              </VStack>
            </TabPanel>}
            {/* <TabPanel>
              <VStack spacing={4}>
                <Box w="full" borderRadius="lg">
              <Heading size="md">Post-processing</Heading>
              <Divider my={5} />
              <FormControl {...sharedFormControlProps}>
                <FormLabel>Post-processing instructions</FormLabel>
                <Textarea
                {...inputStyles}
                  placeholder="Instructions ..."
                  size="sm"
                  minH="32rem"
                  name="post_processing_instructions"
                  value={formData.postProcessing.post_processing_instructions}
                  onChange={(e) => {handleChange('postProcessing', 'post_processing_instructions')(e)}}
                />
              </FormControl>
            </Box>
              </VStack>
            </TabPanel> */}
            {/* { !isDivalogue &&<TabPanel>
              <VStack spacing={4}>
                <Box w="full" borderRadius="lg">
                  <Heading size="md">Human agent escalation</Heading>
                  <Divider my={5} />
                  {Array.isArray(formData.humanEscalation.keywords) && formData.humanEscalation.keywords.map((keyword, index) => (
                    <Flex key={index} gap="1" align="center" mb="4" alignItems="center">
                    <Input
                      key={index}
                      {...inputStyles}
                      placeholder="Enter keyword"
                      value={keyword}
                      onChange={handleKeywordChange(index)}
                      mb={4}
                    />
                    <Icon {...deleteButtonStyles} as={DeleteIcon} onClick={() => confirmDelete(index, 'humanEscalation')} cursor="pointer" mb="4" ml="4"/>
                    </Flex>
                  ))}
                  <Button onClick={addKeyword} {...buttonStyles}>Add Another Keyword</Button>
                </Box>
              </VStack>
            </TabPanel> } */}
            <TabPanel>
              <VStack spacing={4}>
                <Box w="full" borderRadius="lg">
                  <Heading size="md">Integrations</Heading>
                  <Divider my={5} />
                  <FormControl {...sharedFormControlProps}>
                    <FormLabel>MessageBird Channel ID</FormLabel>
                    <Input
                      {...inputStyles}
                      placeholder="MessageBird Channel ID ..."
                      size="sm"
                      name="messagebird_channel_id"
                      value={formData.messagebirdData.messagebird_channel_id}
                      onChange={(e) => handleChange('messagebirdData', 'messagebird_channel_id')(e)}
                    />
                  </FormControl>
                  <FormControl {...sharedFormControlProps}>
                    <FormLabel>MessageBird Workspace ID</FormLabel>
                    <Input
                      {...inputStyles}
                      placeholder="MessageBird Workspace ID ..."
                      size="sm"
                      name="messagebird_workspace_id"
                      value={formData.messagebirdData.messagebird_workspace_id}
                      onChange={(e) => handleChange('messagebirdData', 'messagebird_workspace_id')(e)}
                    />
                  </FormControl>
                  {/* <FormControl {...sharedFormControlProps}>
                    <FormLabel>Phone ID and UUID</FormLabel>
                    <Input
                        {...inputStyles}
                        placeholder="Enter Phone ID and UUID..."
                        size="sm"
                        name="phone_id_and_uuid"
                        value={formData.deviceProfile.phone_id_and_uuid}
                        onChange={(e) => handleChange('deviceProfile', 'phone_id_and_uuid')(e)}
                    />
                </FormControl> */}
                {/* <FormControl {...sharedFormControlProps}>
                    <FormLabel>Advertising ID</FormLabel>
                    <Input
                        {...inputStyles}
                        placeholder="Enter Advertising ID..."
                        size="sm"
                        name="advertising_id"
                        value={formData.deviceProfile.advertising_id}
                        onChange={(e) => handleChange('deviceProfile', 'advertising_id')(e)}
                    />
                </FormControl>
                <FormControl {...sharedFormControlProps}>
                    <FormLabel>Device ID</FormLabel>
                    <Input
                        {...inputStyles}
                        placeholder="Enter Device ID..."
                        size="sm"
                        name="android_device_id"
                        value={formData.deviceProfile.android_device_id}
                        onChange={(e) => handleChange('deviceProfile', 'android_device_id')(e)}
                    />
                </FormControl>
                  <form onSubmit={handleLoginSubmit}>
                  <FormControl {...sharedFormControlProps}>
                    <FormLabel>Instagram Username</FormLabel>
                    <Input
                      {...inputStyles}
                      placeholder="Enter your Instagram username..."
                      size="sm"
                      name="instagram_username"
                    />
                  </FormControl>
                  <FormControl {...sharedFormControlProps}>
                    <FormLabel>Instagram Password</FormLabel>
                    <Input
                      {...inputStyles}
                      type="password"
                      placeholder="Enter your Instagram password..."
                      size="sm"
                      name="instagram_password"
                    />
                  </FormControl> */}
                  {/* <Button 
                    mt="16px"
                    type="submit"
                    w="20%" 
                    h="40px"
                    borderColor={brandShadeColor}
                    borderRadius="8px"
                    borderWidth={"1px"}
                    color={buttonTextColor} 
                    mb="16px" 
                    position="relative"
                    overflow="hidden"
                    transition="transform 0.2s ease-in-out"
                    // Adjust the gradient to be lighter and more subtle
                    background={brandShadeColor}
                    boxShadow={`0 0 0 1px ${brandShadeColor}`}
                    _hover={{ 
                        boxShadow: `0 0 0 2px ${brandShadeColor}`,
                        _hover: {
                          bg: brandColor,
                          borderColor: brandColor,
                              },
                          }}
                      >
                      Submit
                    </Button>
                  </form> */}
                  <CalendlyLogin user={user}/>
                  <Heading size="sm" mb="8px">Usernames Blacklist</Heading>
                  <Divider mb="16px"/>
                  <Box>
                  {
                    formData.usernames && formData.usernames.map((username, index) => (
                      <Flex key={index} my={2} align="center">
                        <Input
                          placeholder={`Username ${index + 1}`}
                          value={username}
                          onChange={(e) => handleUsernameChange(index, e)}
                          {...inputStyles}
                        />
                        <Button
                          ml={2}
                          colorScheme="red"
                          onClick={() => removeUsernameInput(index)}
                        >
                          Remove
                        </Button>
                      </Flex>
                    ))
                  }
                  <Button onClick={addUsernameInput} {...buttonStyles}>Add Username</Button>
                  </Box>
                </Box>
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>
		</Box>
    <div style={{display: "flex", justifyContent: "center"}}>
     <Button 
        w="40%" 
        h="60px"
        borderColor={brandShadeColor}
        borderRadius="22px"
        borderWidth={"1px"}
        color={buttonTextColor} 
        mb="16px" 
        onClick={saveFormData}
        position="relative"
        overflow="hidden"
        transition="transform 0.2s ease-in-out"
        // Adjust the gradient to be lighter and more subtle
        background={brandShadeColor}
        boxShadow={`0 0 0 1px ${brandShadeColor}`}
        _hover={{ 
            boxShadow: `0 0 0 2px ${brandShadeColor}`,
            _hover: {
              bg: brandColor,
              borderColor: brandColor,
            },
        }}
    >
        Save Personality
    </Button>
    </div>
      </Box>
    </Box>
    </>
  );
}