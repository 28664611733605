import React, { useEffect } from 'react';
import { useFormContext } from '../../contexts/FormContext'

const MessageParser = ({ children, actions }) => {
  const { currentThreadId } = useFormContext();

  const parse = (message) => {
    console.log("Parsing message:", message)
    actions.sendMessageToBackend(message);
  };

  useEffect(() => {
    actions.clearChat();
    
  }, [currentThreadId])

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;
