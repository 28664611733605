import React from 'react';
import './Overlay.css'; // Import the CSS file

const Overlay = () => {
    return (
        <div className="overlay">
            <div className="message">Coming Soon</div>
        </div>
    );
};

export default Overlay;
