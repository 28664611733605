import React from "react";

// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";

// Custom components
import { FunnelSystemsLogo, DivalogueLogo } from "components/icons/Icons";
import { HSeparator } from "components/separator/Separator";

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");
  const isDivalogue = process.env.REACT_APP_PLATFORM == "DIVALOGUE";
  console.log(isDivalogue)
  console.log(process.env.REACT_APP_PLATFORM)

  return (
    <Flex align='center' direction='column'>
      {isDivalogue ? <DivalogueLogo h='26px' w='175px' my="32px" color={logoColor}/> : <FunnelSystemsLogo h='26px' w='175px' my="32px" color={logoColor} />}
      <HSeparator mb='20px' />
    </Flex>
  );
}

export default SidebarBrand;
