import React from 'react';

const UserChatMessage = (props) => {
  return (
    <div className="userChatMessageBubble">
      {props.message}
    </div>
  );
};

export default UserChatMessage;
