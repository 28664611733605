import React, { memo, useState } from 'react';
import { Handle, Position, useReactFlow, getConnectedEdges, useStore } from 'reactflow';
import { Box, Divider, Button, FormControl, FormLabel, Input, Textarea, VStack, Heading, useColorModeValue, NumberInput, NumberInputField, Text, HStack, Checkbox, Flex, Spacer } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import { useFormContext } from '../../../contexts/FormContext';
import Card from "components/card/Card"

import styles from './NodeTypes.module.css';

const zoomThreshold = 0.5; // Adjust this threshold as needed
const zoomSelector = (s) => s.transform[2] > zoomThreshold;

const FullContentComponent = ({ nodeData, inputStyles, id, handleChange, chakraFormControlProps, handleDelete, nodeStyles, isDivalogue }) => (
  <Box {...nodeStyles}>
    <Box position="relative">
      <Box position="absolute" top="0" right="0">
        <Button onClick={handleDelete} size="sm" variant="ghost">
          <CloseIcon color="red" />
        </Button>
      </Box>
      <Box p={2} textAlign="left" fontWeight="bold" fontSize="24px">
        {nodeData.stepName || 'Strategy Step Node'}
      </Box>
      <Divider my={2} />
    </Box>
    <VStack spacing={2} height="90%">
    <Flex alignItems="center" justifyContent="space-between" width="100%">
      <FormControl flex="1" {...chakraFormControlProps} isRequired id={`step-name-${id}`}>
        <FormLabel>Step Name</FormLabel>
        <Input size="md" value={nodeData.stepName} {...inputStyles} onChange={handleChange('stepName')} />
      </FormControl>
      { isDivalogue && 
      <FormControl width="auto" paddingLeft="16px">
        <Checkbox
          size="lg"
          {...inputStyles}
          marginTop="10px"
          isChecked={nodeData.isBasePersonalityIgnored}
          onChange={(e) => handleChange('isBasePersonalityIgnored')(e, e.target.checked)}
        >
          Ignore base personality
        </Checkbox>
      </FormControl>
      }
    </Flex>
      { !nodeData.isBasePersonalityIgnored &&
      <FormControl {...chakraFormControlProps} id={`step-goal-${id}`}>
        <FormLabel>Step Goal</FormLabel>
        <Textarea size="md" value={nodeData.stepGoal} {...inputStyles} minH="100px" onChange={handleChange('stepGoal')} />
      </FormControl>
      }
      { !isDivalogue &&
      <FormControl {...chakraFormControlProps} id={`step-criteria-${id}`}>
        <FormLabel>When should the step be chosen</FormLabel>
        <Textarea size="md" value={nodeData.stepCriteria} {...inputStyles} minH="100px" onChange={handleChange('stepCriteria')} />
      </FormControl>
      }
      <FormControl {...chakraFormControlProps} id={`step-behaviour-${id}`}>
        <FormLabel>{ !nodeData.isBasePersonalityIgnored ? "Specific Behaviour" : "Clear stand-alone instructions for the AI in this step"}</FormLabel>
        <Textarea size="md" value={nodeData.stepSpecificBehaviour} {...inputStyles} minH={isDivalogue ? (nodeData.isBasePersonalityIgnored ? "500px" : "350px") : "250px"} onChange={handleChange('stepSpecificBehaviour')} />
      </FormControl>
      <Spacer/>
      <FormControl {...chakraFormControlProps} id={`step-encouragement-${id}`}>
      <FormLabel>Encourage this step between messages number</FormLabel>
      <HStack>
        <NumberInput size="md" value={nodeData.stepEncourageStart} min={0} {...inputStyles} onChange={handleChange('stepEncourageStart')}>
          <NumberInputField id={`step-encourage-start-${id}`} {...inputStyles}/>
        </NumberInput>
        <Text>and</Text>
        <NumberInput size="md" value={nodeData.stepEncourageEnd} min={0} {...inputStyles} onChange={handleChange('stepEncourageEnd')}>
          <NumberInputField id={`step-encourage-end-${id}`} {...inputStyles}/>
        </NumberInput>
      </HStack>
    </FormControl>
    </VStack>
    <Handle className={styles.handle} type="target" position={Position.Top} isConnectable={false} />
    <Handle className={styles.handle} type="source" position={Position.Bottom} isConnectable={false} />
  </Box>
);

const SimpleContentComponent = ({ stepName, nodeStyles }) => (
  <Box display="flex" flexDirection="column" {...nodeStyles}>
    <Heading textAlign="left" fontSize="100px" padding="32px"> {stepName}</Heading>
  </Box>
);

const StrategyStepNode = memo(({ data: initialData, id }) => {
  const showFullContent = useStore(zoomSelector);
  const { updateWorkflowStep, deleteWorkflowStep } = useFormContext();
  const [nodeData, setNodeData] = useState(initialData);
  const reactFlowInstance = useReactFlow();

  const borderColor = useColorModeValue('#3d0048', '#7bd1ff');
  const inputTextColor = useColorModeValue('black', 'black');
  const nodeTextColor = useColorModeValue('black', 'white');

  const handleChange = (field) => (e, value) => {
    const newValue = typeof value === 'boolean' 
    ? value 
    : (field.includes('stepEncourageStart') || field.includes('stepEncourageEnd')) 
        ? value 
        : e.target.value;
    setNodeData((prev) => ({ ...prev, [field]: newValue }));
    updateWorkflowStep(id, { ...nodeData, [field]: newValue });
  };

  const handleDelete = () => {
    const allNodes = reactFlowInstance.getNodes();
    const allEdges = reactFlowInstance.getEdges();
    const nodeId = id.toString();
  
    // Find all connected edges to the node to be deleted
    const connectedEdges = getConnectedEdges([{ id: nodeId }], allEdges);
  
    // Define nodes and edges to delete
    const nodesToDelete = [{ id: nodeId }];
    const edgesToDelete = connectedEdges.map(edge => ({ id: edge.id }));
  
    // Delete the node and connected edges
    reactFlowInstance.deleteElements({
      nodes: nodesToDelete,
      edges: edgesToDelete,
    });
  
    // Identify source and target nodes to find connections to reconnect
    const incomersEdges = allEdges.filter(edge => edge.target === nodeId);
    const outgoersEdges = allEdges.filter(edge => edge.source === nodeId);
  
    const sources = incomersEdges.map(edge => edge.source);
    const targets = outgoersEdges.map(edge => edge.target);
  
    // Create new edges to reconnect the previously connected nodes
    const edgesToReconnect = sources.flatMap(sourceId =>
      targets.map(targetId => {
        return { 
          id: `reconnect-${sourceId}-${targetId}`,
          source: sourceId,
          target: targetId,
          type: 'smoothstep', // or your preferred edge type
          // Any other edge properties you need
        };
      })
    );
  
    // Here we filter out potential duplicate edges that might already exist in the flow
    const newEdges = edgesToReconnect.filter(newEdge => 
      !allEdges.some(edge =>
        edge.source === newEdge.source && edge.target === newEdge.target
      )
    );
  
    // Add the new edges to the flow
    reactFlowInstance.addEdges(newEdges);
    
    // Call any additional cleanup or state updates that need to happen after node deletion
    // Note: If deleteWorkflowStep needs to handle edges, update its signature accordingly
    deleteWorkflowStep(nodeId);
  };

  const inputStyles = {
    borderColor: borderColor,
    _focus: {
      borderColor: borderColor,
      boxShadow: `0 0 0 1px ${borderColor}`,
    },
    color: inputTextColor
  };

  const chakraFormControlProps = {
    marginBottom: '20px',
  };

  const brandColor = useColorModeValue('#3d0048', '#00324d');
  const nodeBackgroundColor = useColorModeValue('rgba(61, 0, 72, 0.1)', 'rgba(123, 209, 255, 0.35)');
  const nodeBackgroundHoverColor = useColorModeValue('rgba(61, 0, 72, 1)', 'rgba(123, 209, 255, 0.65)');
  
  const zoomedOutNodeStyles = {
    color: nodeTextColor,
    borderRadius: "16px",
    borderColor: brandColor,
    borderWidth: "3px",
    borderStyle: "solid",
    height: "875px",
    backgroundColor: nodeBackgroundColor,
    transition: "all 0.3s ease", 
    padding: "32px",
    _hover: {
      backgroundColor: nodeBackgroundHoverColor,
      transform: "scale(1.05)", 
      color: "white",
    },
  };

  const zoomedInNodeStyles = {
    borderColor: brandColor,
    borderWidth: "3px",
    borderStyle: "solid",
    borderRadius: "16px",
    padding: "32px",
    backgroundColor: "white",
    height: "100%",
  };

  const isDivalogue = process.env.REACT_APP_PLATFORM === "DIVALOGUE"

  const content = showFullContent ? (
    <FullContentComponent
      nodeStyles={zoomedInNodeStyles}
      nodeData={nodeData}
      inputStyles={inputStyles}
      id={id}
      handleChange={handleChange}
      chakraFormControlProps={chakraFormControlProps}
      handleDelete={handleDelete}
      isDivalogue={isDivalogue}
    />
  ) : (
    <SimpleContentComponent stepName={nodeData.stepName} nodeStyles={zoomedOutNodeStyles}/>
  );

  return (
    <div className="nowheel"  borderColor="black">
        <div
          style={{height: "875px"}}
          title={nodeData.stepName || 'Strategy Step Node'}
          className={styles.node}
        >
        {content}
      </div>
    </div>
  );
});

export default StrategyStepNode;



