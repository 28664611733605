import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';
import cx from 'classnames';
import { useColorModeValue, Box } from '@chakra-ui/react';

import styles from './NodeTypes.module.css';
import usePlaceholderClick from '../hooks/usePlaceholderClick';

const PlaceholderNode = ({ id, data }) => {
  const brandShadeColor = useColorModeValue('#3d0048', '#00324d');
  const brandColor = useColorModeValue('#3d0048', '#7bd1ff');
  const onClick = usePlaceholderClick(id);
 
  const nodeClasses = cx(styles.node, styles.placeholder);

  const placeholderNodeStyles = {
    borderColor: brandShadeColor,
    borderWidth: "3px",
    borderStyle: "solid",
    borderRadius: "16px",
    padding: "32px",
    backgroundColor: brandShadeColor,
    color: "white",
    fontSize: "100px",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.3s ease", 
    _hover: {
      backgroundColor: brandColor,
      color: "white",
    },
  }

  return (
    <Box onClick={onClick} className={nodeClasses} {...placeholderNodeStyles} title="click to add a node">
      {data.label}
      <Handle className={styles.handle} type="target" position={Position.Top} isConnectable={false} />
      <Handle className={styles.handle} type="source" position={Position.Bottom} isConnectable={false} />
    </Box>
  );
};

export default memo(PlaceholderNode);
