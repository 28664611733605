import React from 'react';

const BotHeader = (props) => {
  return (
    <div className="botHeader">
      Test chatbot
    </div>
  );
};

export default BotHeader;
