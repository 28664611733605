import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { exchangeTokenForLongLivedToken, fetchInstagramData } from '../../service/service'; 
import { useUser } from '@clerk/clerk-react';

const InstagramLoginHandler = () => {
  const history = useHistory();
  const { isLoaded, user } = useUser();

  useEffect(() => {
    if (isLoaded) {
      const clerkUserId = user.id;
      const queryParams = new URLSearchParams(window.location.hash.substring(1));
      const shortLivedToken = queryParams.get('access_token');

      if (shortLivedToken) {
        exchangeAndStoreToken(shortLivedToken, clerkUserId);
      } else {
        console.error('Instagram Login was cancelled or failed');
        history.push('/dashboard');
      }
    }
  }, [history, isLoaded, user]);

  const exchangeAndStoreToken = async (shortLivedToken, clerkUserId) => {
    try {
      const longLivedToken = await exchangeTokenForLongLivedToken(shortLivedToken, clerkUserId);
      if (longLivedToken) {
        const instagramData = await fetchInstagramData(longLivedToken);
        if (instagramData && instagramData.data) {
          instagramData.data.forEach(page => {
            console.log('Page ID:', page.id);
            console.log('Page Name:', page.name);
            console.log('Page Access Token:', page.access_token);
            if (page.instagram_business_account) {
              console.log('Instagram Business Account ID:', page.instagram_business_account.id);
            }
          });
        }
        history.push('/dashboard');
      } else {
        throw new Error('Failed to receive the long-lived access token.');
      }
    } catch (error) {
      console.error('Failed to exchange token or fetch Instagram data:', error);
      history.push('/dashboard');
    }
  };

  return <div>Handling Instagram login response...</div>;
};

export default InstagramLoginHandler;