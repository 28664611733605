import React from 'react';
import CalendlyLoginButton from './CalendlyLoginButton';

const CALENDLY_CLIENT_ID = process.env.REACT_APP_CALENDLY_CLIENT_ID;

const CalendlyLogin = ({ user }) => {
  // Replace with your actual values
  const clientId = CALENDLY_CLIENT_ID;
  const redirectUri = 'https://platform.funnelsystems.ai/calendly-login-handler'

  return <CalendlyLoginButton clientId={clientId} redirectUri={redirectUri} user={user}/>;
};

export default CalendlyLogin;
