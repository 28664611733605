import React, { useCallback, useEffect } from 'react';
import ReactFlow, { Background, ReactFlowProvider, useReactFlow, useNodesInitialized } from 'reactflow';

import useLayout from './hooks/useLayout';
import nodeTypes from './NodeTypes';
import edgeTypes from './EdgeTypes';

import 'reactflow/dist/style.css';
import { useFormContext } from '../../contexts/FormContext'

const proOptions = { account: 'paid-pro', hideAttribution: true };

// initial setup: one workflow node and a placeholder node
// placeholder nodes can be turned into a workflow node by click
const defaultNodes = [
  {
    id: '1',
    type: 'workflow', // The type should match your StrategyStepNode type
    position: { x: 0, y: 0 },
    data: {
      stepName: 'Engage',
      stepGoal: 'Engage with the prospect, build rapport',
      stepSpecificBehaviour: 'Keep your messages short, and only ask very easy questions. Dont make your intentions obvious',
      stepOrder: 1
    },
  },
  {
    id: '2',
    data: { label: '+' },
    position: { x: 0, y: 150 },
    type: 'placeholder',
  },
];

// initial setup: connect the workflow node to the placeholder node with a placeholder edge
const defaultEdges = [
  {
    id: '1=>2',
    source: '1',
    target: '2',
    type: 'placeholder',
  },
];

const fitViewOptions = {
  padding: 0.1,
};

function ReactFlowPro({ initialNodes, initialEdges }) {
  // this hook call ensures that the layout is re-calculated every time the graph changes
  useLayout();

  const nodesInitialized = useNodesInitialized();
  const { fitView, getNode } = useReactFlow();

  const handleNodeClick = useCallback(
    (event, node) => {
      const clickedNode = getNode(node.id);
      if (clickedNode.type == "workflow") {
        console.log(clickedNode)
      }
      // Focus and zoom on the clicked node
      fitView({ nodes: [clickedNode], padding: 0.2, duration: 800 });
    },
    [getNode, fitView]
  );
  

  // Function to handle outside click
  const handleOutsideClick = useCallback(() => {
    // Reset the viewport or fit to view
    fitView({ duration: 800 });
  }, [fitView]);

  const { nodes, edges } = useFormContext(); 

  return (
    <>
      <ReactFlow
        panOnDrag={false}
        defaultNodes={initialNodes}
        defaultEdges={initialEdges}
        proOptions={proOptions}
        fitView
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        fitViewOptions={fitViewOptions}
        minZoom={0.005}
        nodesDraggable={false}
        nodesConnectable={false}
        zoomOnDoubleClick={false}
        zoomOnPinch={false}
        zoomOnScroll={false}
        onNodeClick={handleNodeClick}
        onPaneClick={handleOutsideClick}
      >
        <Background />
      </ReactFlow>
    </>
  );
}

function ReactFlowWrapper({ initialNodes, initialEdges }) {
  return (
    <ReactFlowProvider>
      <ReactFlowPro initialNodes={initialNodes} initialEdges={initialEdges} />
    </ReactFlowProvider>
  );
}

export default ReactFlowWrapper;
