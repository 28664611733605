import React from 'react';
import { getBezierPath } from 'reactflow';

import useEdgeClick from '../hooks/useEdgeClick';
import styles from './EdgeTypes.module.css';
import { useColorModeValue } from '@chakra-ui/react';

export default function CustomEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style,
  markerEnd,
}) {
  // see the hook for implementation details
  // onClick adds a node in between the nodes that are connected by this edge
  const onClick = useEdgeClick(id);

  const [edgePath, edgeCenterX, edgeCenterY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const brandColor = useColorModeValue('#3d0048', '#7bd1ff');
  
  const edgeStyles = {
    stroke: brandColor
  };

  const rectStyles = {
    stroke: brandColor
  }

  const rectTextStyles = {
    fill: brandColor
  }

  return (
    <>
      <path id={id} style={edgeStyles} className={styles.edgePath} d={edgePath} markerEnd={markerEnd} />
      <g transform={`translate(${edgeCenterX}, ${edgeCenterY})`}>
        <rect onClick={onClick} x={-10} y={-10} width={20} ry={4} rx={4} height={20} style={rectStyles} className={styles.edgeButton} />
        <text className={styles.edgeButtonText} style={rectTextStyles} y={5} x={-4}>
          +
        </text>
      </g>
    </>
  );
}
